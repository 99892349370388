import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['button'];

  toggle(event) {
    const currentTarget = event.currentTarget;
    currentTarget.classList.toggle('active');
    const panel = currentTarget.nextElementSibling;

    if (this.element.hasAttribute('data-ui-accordion-auto-close')) {
      this.hideAll(currentTarget);
    }

    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = `${panel.scrollHeight}px`;
      panel.addEventListener(
        'transitionend',
        () => {
          currentTarget.scrollIntoView({ behavior: 'smooth', block: 'center' });
        },
        { once: true },
      );
    }
  }

  hideAll(exceptThis) {
    for (let i = 0; i < this.buttonTargets.length; i++) {
      const panel = this.buttonTargets[i].nextElementSibling;

      if (this.buttonTargets[i] !== exceptThis) {
        this.buttonTargets[i].classList.remove('active');

        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        }
      }
    }
  }
}
